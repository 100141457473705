(elevatorAme = function(){
	var me = elevatorAme;
	me.sogliaTop = 30; //impostazione margine superiore

	if(device == "desktop"){
		try {
			me.target = document.querySelector('.foc-cabin-elevator');
			if(me.target == null) throw '[Ame Elevator] .foc-cabin-elevator non trovato';
		} catch(e) {
			//console.error(e);
			return false;
		}

		try {
			me.wrapperTarget = document.querySelector('.foc-cabin-elevator-wrapper');
			if(me.wrapperTarget == null) throw '[Ame Elevator] .foc-cabin-elevator-wrapper non trovato';
		} catch(e) {
			//console.error(e);
			return false;
		}

		try {
			me.elevatorAmeBase = document.querySelector('.foc-elevator-ame-base');
			if(me.elevatorAmeBase == null) throw '[Ame Elevator] .foc-elevator-ame-base non trovato';
		} catch(e) {
			//console.error(e);
			return false;
		}

		me.firstContainer = me.wrapperTarget.parentNode;
		me.wrapperTarget.style.top = me.sogliaTop+'px';
		me.targetST = me.target.scrollTop;
		me.cabinElevatorObstacles = document.querySelectorAll('.foc-cabin-elevator-obstacle');
		me.cabinElevatorObstaclesLength = me.cabinElevatorObstacles.length;

		me.isCollide = function(a, b)  {
			var aRect = a.getBoundingClientRect();
			var bRect = b.getBoundingClientRect();

			return !(
				((aRect.top + aRect.height) < (bRect.top) - me.sogliaTop) ||
				(aRect.top > (bRect.top + bRect.height)) ||
				((aRect.left + aRect.width) < bRect.left) ||
				(aRect.left > (bRect.left + bRect.width))
			);
		}

		me.checkPosition = function () {
			me.wScrollTop = window.pageYOffset || document.documentElement.scrollTop;
			me.targetRect = me.target.getBoundingClientRect();
			me.firstContainerOT =  me.firstContainer.getBoundingClientRect().top;
			me.targetH = me.target.offsetHeight;
			me.wrapperTarget.style.height = me.targetH+'px';

			//gestion gancio e sgancio in top
			if(me.firstContainerOT <= me.sogliaTop  && !me.wrapperTarget.classList.contains('foc-elevator-ame-fixed')) {
				me.wrapperTarget.classList.add('foc-elevator-ame-fixed');
			} else if (me.firstContainerOT > me.sogliaTop && me.wrapperTarget.classList.contains('foc-elevator-ame-fixed')) {
				me.wrapperTarget.classList.remove('foc-elevator-ame-fixed');
			}

			//verifichiamo se cabin Ã¨ in collision
			me.toShow = 1;
			for(i=0;i<me.cabinElevatorObstaclesLength;i++) {
				if(me.isCollide(me.wrapperTarget, me.cabinElevatorObstacles[i])) {
					me.activeObstacleH = me.cabinElevatorObstacles[i].offsetHeight;
					me.rectObstacle = me.cabinElevatorObstacles[i].getBoundingClientRect();
					me.toShow = 0;
				}
			}

			me.rectWrapper = me.wrapperTarget.getBoundingClientRect();
			if(me.toShow == 0) {
				//se Ã¨ in collision
				me.target.style.top = me.sogliaTop + me.rectObstacle.top+me.activeObstacleH+'px';
			} else if (me.toShow == 1) { //se non Ã¨ in collision
				me.target.style.top = me.sogliaTop+'px';
			}

			//arrivato in fondo
			me.elevatorAmeBaseRect = me.elevatorAmeBase.getBoundingClientRect();
			if(me.isCollide(me.wrapperTarget, me.elevatorAmeBase) || me.rectWrapper.top > me.elevatorAmeBaseRect.top ) {
				me.target.style.top = (me.elevatorAmeBaseRect.top - me.targetH-me.sogliaTop)+'px';
			}

			//se siamo arrivati a fine corsa
			if(me.elevatorAmeBase.getBoundingClientRect().top - me.targetH < 0 || me.isCollide(me.target,me.elevatorAmeBase)) {
				if(!me.target.classList.contains('foc-cabin-elevator-hide')) {
					me.target.classList.add('foc-cabin-elevator-hide');
				}
			} else if (me.target.classList.contains('foc-cabin-elevator-hide')) {
				me.target.classList.remove('foc-cabin-elevator-hide');
			}
		}

		window.requestAnimationFrame(
			function() {
				me.checkPosition();
			}
		);

		window.addEventListener('scroll', function() {
			window.requestAnimationFrame(
				function() {
					me.checkPosition();
				}
			);
		});
	}
})();